<template>
  <div>
    <div class="aboutHeader">
      <h3>
        Artificial Teddy Rose Flower Bear Women Girl Valentine Gifts Wedding
        Party Decor
      </h3>
      <div class="aboutHeader-link">
        <p>
          <router-link style="text-decoration: none" to="/">
            <v-icon style="margin-top: -4px"> mdi-home </v-icon>
          </router-link>
          <router-link
            style="text-decoration: none; color: black"
            to="/homeDecor"
          >
            > Home Decor
          </router-link>
          <router-link
            to="/nightStar"
            style="text-decoration: none; color: black"
          >
            > Artificial Teddy Rose Flower Bear Women Girl Valentine Gifts
            Wedding Party Decor
          </router-link>
        </p>
      </div>
    </div>

    <div class="productMain">
      <div class="productImage">
        <carousel :per-page="1" :navigateTo="[this.slideNum]">
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/teddyFlower/1.Main Image.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/teddyFlower/2. Red.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/teddyFlower/3. Pink.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/teddyFlower/4. Purple.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/homeDecor/teddyFlower/5.jpg"
              alt=""
            />
          </slide>
        </carousel>
      </div>

      <div class="productContent">
        <h2 class="pTitle">
          Artificial Teddy Rose Flower Bear Women Girl Valentine Gifts Wedding
          Party Decor
        </h2>
        <h2 style="color: #38872c">$35.00</h2>
        <p>
          1. PE immortal flowers, odorless, fluffy three-dimensional, ultra-soft
          feel crumpled, waterproof, durable and fadeless.
        </p>
        <p>
          2. Fashionable gifts, hand-made, three-dimensional, petals can be
          restored after being squeezed.
        </p>
        <p>
          3. Suitable for all special occasions: Valentine's Day, Mothers' Day,
          Wedding, Christmas, Anniversary, Birthday etc
        </p>
        <p>4. This is the perfect gift for your sweetheart or wife.</p>
        <p>5. Showing you the true long-term love for her.</p>
        <h4>
          Size:
          <span style="margin-left: 5px; color: #38872c">{{ this.color }}</span>
        </h4>
        <div class="size">
          <div class="size1">
            <p :class="{ activeSize: red }" @click="size1">
              <img
                style="width: 50px"
                src="../../assets/images/homeDecor/teddyFlower/2. Red.jpg"
                alt="image"
              />
            </p>
            <p :class="{ activeSize: pink }" @click="size2">
              <img
                style="width: 50px"
                src="../../assets/images/homeDecor/teddyFlower/3. Pink.jpg"
                alt="image"
              />
            </p>
            <p :class="{ activeSize: purple }" @click="size3">
              <img
                style="width: 50px"
                src="../../assets/images/homeDecor/teddyFlower/4. Purple.jpg"
                alt="image"
              />
            </p>
          </div>
        </div>
        <p v-if="this.inStock >= 1" style="color: #38872c">
          {{ inStock }} in stock
        </p>
        <p v-else style="color: red; font-weight: bold">
          {{ inStock }} in stock
        </p>
        <div class="counter">
          <div class="nCounter" @click="nCount">
            <v-icon> mdi-minus </v-icon>
          </div>
          <div class="count">{{ count }}</div>
          <div class="pCounter" @click="pCount">
            <v-icon> mdi-plus </v-icon>
          </div>
        </div>
        <button @click="addToCart">Add to cart</button>
      </div>
    </div>

    <div class="deatailsContent1">
      <h2>Description:</h2>
      <p>
        25cm Handmade Rose Flower Bear Artificial Decor Girlfriend Mother Gift
        Doll Wedding Birthday Valentine
      </p>

      <h2>Features:</h2>
      <p>
        1. PE immortal flowers, odorless, fluffy three-dimensional, ultra-soft
        feel crumpled, waterproof, durable and fadeless.
      </p>
      <p>
        2. Fashionable gifts, hand-made, three-dimensional, petals can be
        restored after being squeezed.
      </p>
      <p>
        3. Suitable for all special occasions: Valentine's Day, Mothers' Day,
        Wedding, Christmas, Anniversary, Birthday etc
      </p>
      <p>4. This is the perfect gift for your sweetheart or wife.</p>
      <p>5. Showing you the true long-term love for her.</p>

      <h2>Specification:</h2>
      <p>Color: Red/Purple/Light Pink</p>
      <p>Material: PE foam</p>
      <p>Size: 25*16cm</p>
    </div>

    <div class="headerTitle">
      <h2>
        Related <span style="color: #38872c; font-weight: 700">Products</span>
      </h2>

      <div class="hr">
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 100px" />
      </div>
    </div>

    <div class="top">
      <div class="topBoxes">
        <div class="topContent-1">
          <div @click="bikeStand" class="topBox-2 bikeStand">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>1-3 Bike Stand Bicycle Rack Storage Floor Parking Holder</h2>
            <p>$55.00</p>
          </div>

          <div @click="rgb" class="topBox-3 rgb">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>Rgb led string room lights decor tree lamp for bedroom</h2>
            <p>$40.00</p>
          </div>

          <div @click="wallLamp" class="topBox-3 wallLamp">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>2PCS Solar LED Light Outdoor Wall Lamp Garden Light</h2>
            <p>$25.00</p>
          </div>
        </div>
      </div>
    </div>

    <ErrorDialog v-model="error" />
  </div>
</template>

<script>
import ErrorDialog from "../../components/ErrorDialog.vue";
export default {
  data() {
    return {
      slideNum: 0,
      count: 1,
      carts: {},
      titleClass: true,
      deatails1: true,
      deatails2: false,
      color: "Red",
      red: true,
      purple: false,
      pink: false,
      error: false,
      inStock: 49,
    };
  },
  methods: {
    bikeStand() {
      this.$router.push("/bikeStand");
    },
    rgb() {
      this.$router.push("/rgb");
    },
    wallLamp() {
      this.$router.push("/wallLamp");
    },
    description1() {
      this.deatails1 = true;
      this.deatails2 = false;
      this.titleClass = true;
    },
    description2() {
      this.deatails1 = false;
      this.deatails2 = true;
      this.titleClass = false;
    },
    pCount() {
      if (this.inStock != 0) {
        this.count += 1;
        this.inStock -= 1;
      }
    },
    nCount() {
      if (this.count > 1) {
        this.count -= 1;
        this.inStock += 1;
      }
    },
    size1() {
      this.color = "Red";
      this.slideNum = 1;
      this.red = true;
      this.purple = false;
      this.pink = false;
    },
    size2() {
      this.color = "Pink";
      this.slideNum = 2;
      this.red = false;
      this.purple = false;
      this.pink = true;
    },
    size3() {
      this.color = "Purple";
      this.slideNum = 3;
      this.red = false;
      this.purple = true;
      this.pink = false;
    },
    addToCart() {
      const product = {
        id: "teddyFlower",
        productTitle:
          "Artificial Teddy Rose Flower Bear Women Girl Valentine Gifts Wedding Party Decor",
        price: 35.0,
        quantity: this.count,
        subTotal: this.count * 35.0,
        color: this.color,
      };

      this.carts[product.id] = product;
      localStorage.setItem("cart", JSON.stringify(this.carts));
      this.error = true;
      this.count = 1;
    },
  },
  mounted() {
    this.carts = JSON.parse(localStorage.getItem("cart"));
    if (!this.carts) this.carts = {};
  },
  components: { ErrorDialog },
};
</script>

<style scoped>
.size1 {
  display: flex;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .size {
    display: flex;
    flex-direction: column;
  }

  .size p {
    margin: 10px 20px;
    width: 17%;
  }
}
</style>
