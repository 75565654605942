<template>
  <div>
    <div class="aboutHeader">
      <h2>Health & Beauty</h2>
      <div class="aboutHeader-link">
        <p>
          <router-link style="text-decoration: none" to="/">
            <v-icon style="margin-top: -4px"> mdi-home </v-icon>
          </router-link>
          <router-link
            style="text-decoration: none; color: black"
            to="/homeDecor"
          >
            > Health & Beauty
          </router-link>
        </p>
      </div>
    </div>

    <div class="headerTitle">
      <h2>
        Health & <span style="color: #38872c; font-weight: 700">Beauty</span>
      </h2>

      <div class="hr">
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 100px" />
      </div>
    </div>

    <div class="top">
      <div class="topBoxes">
        <div class="topContent-1">
          <div @click="shilajit" class="topBox-1 shilajit">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>Shilajit</h2>
            <p><span>$59.99</span> $49.99</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    shilajit() {
      this.$router.push("/products/grade-a-shilajit");
    },
  },
};
</script>

<style>
.shilajit {
  background-image: url("../../assets/images/silajeet/1.webp");
}

.shilajit:hover {
  background-image: url("../../assets/images/silajeet/2.webp");
}
</style>
