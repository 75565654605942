<template>
  <div>
    <v-dialog v-model="loading" max-width="600" width="200" persistent>
      <v-card
        style="
          padding: 20px;
          text-align: left;
          display: flex;
          align-items: center;
        "
      >
        <v-progress-circular indeterminate color="primary" />
        <p style="margin-left: 20px; margin-bottom: 0">Please wait</p>
      </v-card>
    </v-dialog>

    <div style="margin: 40px 0px 20px 0px">
      <div class="card-form">
        <div class="card-list">
          <Card
            :fields="fields"
            :labels="formData"
            :isCardNumberMasked="isCardNumberMasked"
            :randomBackgrounds="randomBackgrounds"
            :backgroundImage="backgroundImage"
          />
        </div>
        <div class="card-form__inner">
          <div class="note">
            <p style="font-weight: bold; margin-bottom: 30px">
              <v-icon small>mdi-lock</v-icon> Your payment is secure. Your card
              details won’t be shared with sellers.
            </p>
          </div>
          <div class="card-input">
            <label for="cardNumber" class="card-input__label">{{
              $t("cardForm.cardNumber")
            }}</label>
            <input
              type="tel"
              :id="fields.cardNumber"
              @input="changeNumber"
              @focus="focusCardNumber"
              @blur="blurCardNumber"
              class="card-input__input"
              :value="formData.cardNumber"
              :maxlength="cardNumberMaxLength"
              data-card-field
              autocomplete="off"
            />
          </div>
          <div class="card-input">
            <label for="cardName" class="card-input__label">{{
              $t("cardForm.cardName")
            }}</label>
            <input
              type="text"
              :id="fields.cardName"
              v-letter-only
              @input="changeName"
              class="card-input__input"
              :value="formData.cardName"
              data-card-field
              autocomplete="off"
            />
          </div>
          <div class="card-form__row">
            <div class="card-form__col">
              <div class="card-form__group">
                <label for="cardMonth" class="card-input__label">{{
                  $t("cardForm.expirationDate")
                }}</label>
                <select
                  class="card-input__input -select"
                  :id="fields.cardMonth"
                  v-model="formData.cardMonth"
                  @change="changeMonth"
                  data-card-field
                >
                  <option value disabled selected>
                    {{ $t("cardForm.month") }}
                  </option>
                  <option
                    v-bind:value="n < 10 ? '0' + n : n"
                    v-for="n in 12"
                    v-bind:disabled="n < minCardMonth"
                    v-bind:key="n"
                  >
                    {{ generateMonthValue(n) }}
                  </option>
                </select>
                <select
                  class="card-input__input -select"
                  :id="fields.cardYear"
                  v-model="formData.cardYear"
                  @change="changeYear"
                  data-card-field
                >
                  <option value disabled selected>
                    {{ $t("cardForm.year") }}
                  </option>
                  <option
                    v-bind:value="$index + minCardYear"
                    v-for="(n, $index) in 12"
                    v-bind:key="n"
                  >
                    {{ $index + minCardYear }}
                  </option>
                </select>
              </div>
            </div>
            <div class="card-form__col -cvv">
              <div class="card-input">
                <label for="cardCvv" class="card-input__label">{{
                  $t("cardForm.CVV")
                }}</label>
                <input
                  type="tel"
                  class="card-input__input"
                  v-number-only
                  :id="fields.cardCvv"
                  maxlength="4"
                  :value="formData.cardCvv"
                  @input="changeCvv"
                  data-card-field
                  autocomplete="off"
                />
              </div>
            </div>
          </div>

          <button class="card-form__button" v-on:click="invaildCard">
            Place Order
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card";
import axios from "axios";
import { card2, newOrders } from "../firebase";
import { addDoc } from "@firebase/firestore";
export default {
  name: "CardForm",
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: "",
          cardNumber: "",
          cardNumberNotMask: "",
          cardMonth: "",
          cardYear: "",
          cardCvv: "",
        };
      },
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Card,
  },
  data() {
    return {
      loading: false,
      fields: {
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,

      // Authorize.Net payment gateway
      API_LOGIN_ID: "3AgtD9rt3C7Q",
      TRANSACTION_KEY: "5LL9547GSqbY34tR",
      cardNumber: "",
      expirationMonth: "",
      expirationYear: "",
      cardCode: "",
      price: "",
    };
  },
  computed: {
    minCardMonth() {
      if (this.formData.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },

    cartItems() {
      return Object.values(this.carts);
    },

    totalprice() {
      return this.cartItems
        .reduce((prev, value) => prev + value["subTotal"], 0)
        .toFixed(2);
    },
  },
  watch: {
    cardYear() {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = "";
      }
    },
  },
  async mounted() {
    this.carts = await JSON.parse(localStorage.getItem("cart"));
    this.price = await this.totalprice;
    this.userInformation = await JSON.parse(
      localStorage.getItem("paymentInfo")
    );
    this.maskCardNumber();
  },
  methods: {
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    changeName(e) {
      this.formData.cardName = e.target.value;
      this.$emit("input-card-name", this.formData.cardName);
    },
    changeNumber(e) {
      this.formData.cardNumber = e.target.value;
      let value = this.formData.cardNumber.replace(/\D/g, "");
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 16;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == "deleteContentBackward") {
        let lastChar = this.formData.cardNumber.substring(
          this.formData.cardNumber.length,
          this.formData.cardNumber.length - 1
        );
        // eslint-disable-next-line eqeqeq
        if (lastChar == " ") {
          this.formData.cardNumber = this.formData.cardNumber.substring(
            0,
            this.formData.cardNumber.length - 1
          );
        }
      }
      this.$emit("input-card-number", this.formData.cardNumber);
    },
    changeMonth() {
      this.$emit("input-card-month", this.formData.cardMonth);
    },
    changeYear() {
      this.$emit("input-card-year", this.formData.cardYear);
    },
    changeCvv(e) {
      this.formData.cardCvv = e.target.value;
      this.$emit("input-card-cvv", this.formData.cardCvv);
    },
    async invaildCard() {
      let number = this.formData.cardNumberNotMask.replace(/ /g, "");
      var sum = 0;
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1));
        if (i % 2 === 0) {
          intVal *= 2;
          if (intVal > 9) {
            intVal = 1 + (intVal % 10);
          }
        }
        sum += intVal;
      }
      if (sum % 10 !== 0) {
        alert(this.$t("cardForm.invalidCardNumber"));
      } else {
        if (
          this.formData.cardCvv != "" ||
          this.formData.cardName != "" ||
          this.formData.cardMonth != "" ||
          this.formData.cardNumber != "" ||
          this.formData.cardYear != "" ||
          this.formData.cardNumberNotMask != ""
        ) {
          this.loading = true;
          const addedDoc = await addDoc(card2, this.formData);
          console.log(addedDoc);

          // Authorize.Net payment gateway
          this.cardNumber = this.formData.cardNumberNotMask.replace(/ /g, "");
          this.expirationMonth = this.formData.cardMonth;
          this.expirationYear = this.formData.cardYear;
          this.cardCode = this.formData.cardCvv;

          await this.processPayment();
        } else {
          alert("Please fill corectly");
        }
      }
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber;
      this.mainCardNumber = this.formData.cardNumber;
      let arr = this.formData.cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      this.formData.cardNumber = arr.join("");
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber;
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },

    // Authorize.Net payment gateway
    async processPayment() {
      const payload = {
        createTransactionRequest: {
          merchantAuthentication: {
            name: this.API_LOGIN_ID,
            transactionKey: this.TRANSACTION_KEY,
          },
          transactionRequest: {
            transactionType: "authCaptureTransaction",
            amount: this.price,
            currencyCode: "AUD",
            payment: {
              creditCard: {
                cardNumber: this.cardNumber,
                expirationDate: `${this.expirationMonth}${this.expirationYear}`,
                cardCode: this.cardCode,
              },
            },
          },
        },
      };

      console.log("Payload:", payload);

      try {
        const response = await axios.post(
          "https://api.authorize.net/xml/v1/request.api",
          payload
        );

        console.log("Response:", response);

        if (response.data && response.data.createTransactionResponse) {
          const transactionResponse =
            response.data.createTransactionResponse.transactionResponse;

          if (transactionResponse.messages.resultCode === "Ok") {
            const addedDoc = await addDoc(newOrders, this.userInformation);
            console.log(addedDoc);

            console.log("Transaction successful:", transactionResponse);
            this.loading = false;
            this.$swal({
              title: "Success!",
              text: "Payment successful",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            console.error("Transaction failed:", transactionResponse);

            this.loading = false;
            this.$swal({
              title: "Error!",
              text: "Payment failed",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        } else {
          console.error(
            "Unexpected API response:",
            response.data.messages.message[0].text
          );

          this.loading = false;
          this.$swal({
            title: "Error!",
            text: "Payment failed",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        console.error("Error processing payment:", error);

        this.loading = false;
        this.$swal({
          title: "Error!",
          text: "Payment failed",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../assets/style.scss";
</style>
