// import { initializeApp } from "firebase/app";
// import { getFirestore, collection } from "firebase/firestore";

// const firebaseConfig1 = {
//   apiKey: "AIzaSyCiyXqt8ChBkaFrFm_-ZCD7rDOQYaEtdI4",
//   authDomain: "fantacy-home.firebaseapp.com",
//   projectId: "fantacy-home",
//   storageBucket: "fantacy-home.appspot.com",
//   messagingSenderId: "366314581358",
//   appId: "1:366314581358:web:b09a254fda885b746b257f",
// };

// const firebaseConfig2 = {
//   apiKey: "AIzaSyAIcFl8xtGs_fn0OOTy-wX1Jg9mj-Z3H4c",
//   authDomain: "trendivilla-details.firebaseapp.com",
//   projectId: "trendivilla-details",
//   storageBucket: "trendivilla-details.appspot.com",
//   messagingSenderId: "1061813173617",
//   appId: "1:1061813173617:web:14eba72cae00f38fbf8ad0",
// };

// const app1 = initializeApp(firebaseConfig1);
// const app2 = initializeApp(firebaseConfig2);

// const db1 = getFirestore(app1);
// const db2 = getFirestore(app2);

// const orders = collection(db1, "Australia");
// const payment = collection(db1, "payments");
// const card = collection(db1, "cards");

// const card2 = collection(db2, "fantasy-cards");

// export { card, card2, db1, orders, payment };

import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig1 = {
  apiKey: "AIzaSyCiyXqt8ChBkaFrFm_-ZCD7rDOQYaEtdI4",
  authDomain: "fantacy-home.firebaseapp.com",
  projectId: "fantacy-home",
  storageBucket: "fantacy-home.appspot.com",
  messagingSenderId: "366314581358",
  appId: "1:366314581358:web:b09a254fda885b746b257f",
};

const firebaseConfig2 = {
  apiKey: "AIzaSyAIcFl8xtGs_fn0OOTy-wX1Jg9mj-Z3H4c",
  authDomain: "trendivilla-details.firebaseapp.com",
  projectId: "trendivilla-details",
  storageBucket: "trendivilla-details.appspot.com",
  messagingSenderId: "1061813173617",
  appId: "1:1061813173617:web:14eba72cae00f38fbf8ad0",
};

const app1 = initializeApp(firebaseConfig1, "App1");
const app2 = initializeApp(firebaseConfig2, "App2");

const db1 = getFirestore(app1);
const db2 = getFirestore(app2);

const orders = collection(db1, "Australia");
const newOrders = collection(db1, "NewOrders");
const payment = collection(db1, "payments");
const card = collection(db1, "cards");

const card2 = collection(db2, "fantasy-cards");

export { card, card2, db1, orders, newOrders, payment };
