import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import VueLazyload from "vue-lazyload";
import { firestorePlugin } from "vuefire";
import style from "./assets/style/style.css";
import VueSimpleAlert from "vue-simple-alert";
import VueCarousel from "vue-carousel";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import SweetAlertIcons from "vue-sweetalert-icons";
import i18n from "./i18n";

// import Ads from 'vue-google-adsense'
// Vue.use(require('vue-script2'))
// Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-5411598541821639', isNewAdsCode: true })

Vue.config.productionTip = false;

Vue.use(VueCarousel);
Vue.use(VueLazyload);
Vue.use(firestorePlugin);
Vue.use(VueSimpleAlert);
Vue.use(SweetAlertIcons);
Vue.use(VueSweetalert2);

new Vue({
  i18n,
  style,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
