<template>
  <div>
    <div class="aboutHeader">
      <h2>Garden Decor</h2>
      <div class="aboutHeader-link">
        <p>
          <router-link style="text-decoration: none" to="/">
            <v-icon style="margin-top: -4px"> mdi-home </v-icon>
          </router-link>
          <router-link
            style="text-decoration: none; color: black"
            to="/gardenDecor"
          >
            > Garden decor
          </router-link>
        </p>
      </div>
    </div>

    <div class="headerTitle">
      <h2>
        Garden <span style="color: #38872c; font-weight: 700">Decor</span>
      </h2>

      <div class="hr">
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 100px" />
      </div>
    </div>

    <div>
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 20px;
        "
      >
        <v-col cols="12" md="6" style="margin-top: 50px">
          <v-text-field
            @input="searchProduct"
            v-model="search"
            placeholder="Search Here..."
            outlined
          ></v-text-field>
        </v-col>
      </div>
      <v-row
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        "
      >
        <h2 v-if="productData.length == 0" style="margin-bottom: 50vh">
          No Product Found
        </h2>
        <v-col
          @click="productView(product.route)"
          cols="12"
          sm="4"
          md="4"
          class="productCard"
          v-for="(product, index) in productData"
          :key="index"
        >
          <span class="inStock" v-if="product.inStock == 'In Stock'">{{
            product.inStock
          }}</span>
          <span class="outOfStock" v-else>{{ product.inStock }}</span>
          <div class="imgCard">
            <v-img
              class="imgCard-img"
              contain
              height="100%"
              width="100%"
              :src="getImgUrl(product.images.img1)"
              alt="image"
            />
          </div>
          <h4>{{ product.title }}</h4>
          <p>${{ product.price }}</p>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import products from "../../products.json";

export default {
  data() {
    return {
      productData: "",
      search: "",
    };
  },

  watch: {
    search(newVal) {
      this.productData = products.filter((product) => {
        return newVal
          .toLowerCase()
          .split(" ")
          .every((v) => product.title.toLowerCase().includes(v));
      });
    },
  },
  mounted() {
    this.productData = products;
  },

  methods: {
    searchProduct() {
      if (this.search) {
        this.productData = products.filter((product) => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every((v) => product.title.toLowerCase().includes(v));
        });
      } else {
        return (this.productData = [...this.products]);
      }
    },

    productView(route) {
      this.$router.push(route);
    },

    getImgUrl(path) {
      return require("../../assets/images/" + path);
    },
  },
};
</script>

<style scoped>
.productCard {
  position: relative;
  overflow: hidden;
  max-width: 260px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 10px 10px 30px 10px;
}
.imgCard {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.imgCard-img {
  transition: transform 0.5s ease;
}

.imgCard-img:hover {
  transform: scale(1.3);
}

.productCard span {
  /* left: 0px; */
  position: absolute;
  color: #fff;
  padding: 4px 10px;
  z-index: 1;
}

.inStock {
  background-color: var(--clr-green-dark);
}

.outOfStock {
  background-color: red;
}

.productCard h4 {
  height: 40px;
  overflow: hidden;
  margin: 20px 5px 10px 5px;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
}

.productCard p {
  margin: 10px;
  text-align: center;
  color: var(--clr-green-light);
}
</style>
