<template>
  <div>
    <div class="aboutHeader">
      <h3>
        3 Head Solar Motion Sensor Light Outdoor Garden Wall Security Flood Lamp
      </h3>
      <div class="aboutHeader-link">
        <p>
          <router-link style="text-decoration: none" to="/">
            <v-icon style="margin-top: -4px"> mdi-home </v-icon>
          </router-link>
          <router-link
            style="text-decoration: none; color: black"
            to="/gardenDecor"
          >
            > Garden Decor
          </router-link>
          <router-link to="/owl" style="text-decoration: none; color: black">
            > 3 Head Solar Motion Sensor Light Outdoor Garden Wall Security
            Flood Lamp
          </router-link>
        </p>
      </div>
    </div>

    <div class="productMain">
      <div class="productImage">
        <carousel :per-page="1">
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/gardenDecor/solarMotion/1.png"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/gardenDecor/solarMotion/2.png"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/gardenDecor/solarMotion/3.jpg"
              alt=""
            />
          </slide>
          <slide>
            <img
              style="width: 100%"
              src="../../assets/images/gardenDecor/solarMotion/4.png"
              alt=""
            />
          </slide>
        </carousel>
      </div>

      <div class="productContent">
        <h2 class="pTitle">
          3 Head Solar Motion Sensor Light Outdoor Garden Wall Security Flood
          Lamp
        </h2>
        <h2 style="color: #38872c">$35.00</h2>
        <p>Safe Material: ABS + PC</p>
        <p>Solar Panel: 1.8W, 5.5V</p>
        <p>Wattage: 15W</p>
        <p>LED quantity: 74pcs</p>
        <p>Lumen: 800LM</p>
        <p>Lighting Angle: 270 degrees</p>
        <p>Sensor Angle:120 Degree</p>
        <p>Sensing Distance: 16- 32Feet</p>
        <p>Li-ion Battery: 3.7V 2400mAh</p>
        <p>Color Temperature: 6500K (White Light)</p>

        <p v-if="this.inStock >= 1" style="color: #38872c">
          {{ inStock }} in stock
        </p>
        <p v-else style="color: red; font-weight: bold">
          {{ inStock }} in stock
        </p>
        <div class="counter">
          <div class="nCounter" @click="nCount">
            <v-icon> mdi-minus </v-icon>
          </div>
          <div class="count">{{ count }}</div>
          <div class="pCounter" @click="pCount">
            <v-icon> mdi-plus </v-icon>
          </div>
        </div>
        <button @click="addToCart">Add to cart</button>
      </div>
    </div>

    <div class="deatailsHeader">
      <p class="p1">Features:</p>
    </div>

    <div class="deatailsContent1">
      <p>
        All angle covered -- The flexible light heads on both side can be
        rotated freely to any direction you want.The solar panel can also be
        freely adjusted to face the sun to absorb solar energy with maximum
        efficiency.
      </p>
      <p>
        Charges at day, shines at night -- This solar motion light stays off and
        absorb sunlight to charge its high capacity (2400 mA) battery during the
        day. When night comes, the light turns on automatically and stays at
        low-consumption dim mode until movement is detected.
      </p>
      <p>
        The smartest sensor -- The built-in PIR sensor detects movements up to
        32 ft. When motion is detected, the light turns to maximum brightness
        for 60s, and then will turn off if no motion is detected.
      </p>
      <p>
        IP65 Waterproof -- Specifically designed for outdoor use, cshidworld
        solar wall light is made of premium ABS, with strong durability under
        all weather including rain, storm, snow and frost. Remain functional
        whether it's freezing winter or scorching summer.
      </p>

      <h2>Specifications:</h2>
      <p>Safe Material: ABS + PC</p>
      <p>Solar Panel: 1.8W, 5.5V</p>
      <p>Wattage: 15W</p>
      <p>LED quantity: 74pcs</p>
      <p>Lumen: 800LM</p>
      <p>Lighting Angle: 270 degrees</p>
      <p>Sensor Angle:120 Degree</p>
      <p>Sensing Distance: 16- 32Feet</p>
      <p>Li-ion Battery: 3.7V 2400mAh</p>
      <p>Color Temperature: 6500K (White Light)</p>
    </div>

    <div class="headerTitle">
      <h2>
        Related <span style="color: #38872c; font-weight: 700">Products</span>
      </h2>

      <div class="hr">
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 3px" />
        <hr style="border: 2px solid green; width: 100px" />
      </div>
    </div>

    <div class="top">
      <div class="topBoxes">
        <div class="topContent-1">
          <div @click="lightHouse" class="topBox-1 lightHouse">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>Lighthouse Decorative Light Indoor Outdoor Solar Powered</h2>
            <p>$55.00</p>
          </div>

          <div @click="dierya" class="topBox-2 dierya">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>
              Dierya Outdoor Solar Powered Floating Water Fountain Pump Garden
              Pond
            </h2>
            <p>$30.00</p>
          </div>

          <div @click="ledSolar" class="topBox-4 ledOutdoor">
            <h4 style="background-color: #38872c; padding-left: 30px">
              In Stock
            </h4>
            <h2>
              4X LED Solar Lights Outdoor Garden Decoration Garden/Pathway
            </h2>
            <p>$35.00</p>
          </div>
        </div>
      </div>
    </div>

    <ErrorDialog v-model="error" />
  </div>
</template>

<script>
import ErrorDialog from "../../components/ErrorDialog.vue";
export default {
  data() {
    return {
      price: 35,
      count: 1,
      carts: [],
      // items: [
      //   {
      //     src: require("../../assets/images/gardenDecor/solarMotion/1.png"),
      //   },
      //   {
      //     src: require("../../assets/images/gardenDecor/solarMotion/2.png"),
      //   },
      //   {
      //     src: require("../../assets/images/gardenDecor/solarMotion/3.jpg"),
      //   },
      //   {
      //     src: require("../../assets/images/gardenDecor/solarMotion/4.png"),
      //   },
      // ],
      error: false,
      inStock: 36,
    };
  },
  methods: {
    lightHouse() {
      this.$router.push("/lightHouse");
    },
    dierya() {
      this.$router.push("/dierya");
    },
    ledSolar() {
      this.$router.push("/ledSolar");
    },
    pCount() {
      if (this.inStock != 0) {
        this.count += 1;
        this.inStock -= 1;
      }
    },
    nCount() {
      if (this.count > 1) {
        this.count -= 1;
        this.inStock += 1;
      }
    },
    addToCart() {
      const product = {
        id: "owl",
        productTitle: "Solar Waterproof LED Garden Lights Owl",
        price: this.price,
        quantity: this.count,
        subTotal: this.count * this.price,
      };

      this.carts[product.id] = product;
      localStorage.setItem("cart", JSON.stringify(this.carts));
      this.error = true;
      this.count = 1;
    },
  },
  mounted() {
    this.carts = JSON.parse(localStorage.getItem("cart"));
    if (!this.carts) this.carts = {};
  },
  components: { ErrorDialog },
};
</script>

<style>
.lightHouse {
  background-image: url("../../assets/images/gardenDecor/lighthouse/300-1.jpg");
}

.lightHouse:hover {
  background-image: url("../../assets/images/gardenDecor/lighthouse/300-2.jpg");
}

.dierya {
  background-image: url("../../assets/images/gardenDecor/dierya/300-1.jpg");
}

.dierya:hover {
  background-image: url("../../assets/images/gardenDecor/dierya/300-2.jpg");
}

.ledOutdoor {
  background-image: url("../../assets/images/gardenDecor/ledSolar/1.jpg");
}

.ledOutdoor:hover {
  background-image: url("../../assets/images/gardenDecor/ledSolar/2.jpeg");
}
</style>
